.hero {
  /* width: 100vh;
  height: 100vh; */
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
      to left,
      rgba(73, 50, 173, 0.5),
      rgba(73, 50, 173, 0.9)
    ),
    url('https://images.pexels.com/photos/1464223/pexels-photo-1464223.jpeg?auto=compress&cs=tinysrgb&w=1800');
  background-position: left;
  background-repeat: no-repeat;
}
.hero .container {
  max-width: 1240px;
  padding: 0 1rem;
  margin: auto;
}
/* animation*/

#scroll-container {
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
}

#scroll-text {
  text-align: right;

  /* animation properties */
  -moz-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);

  -moz-animation: my-animation 5s linear infinite;
  -webkit-animation: my-animation 5s linear infinite;
  animation: my-animation 15s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateX(-100%);
  }
  to {
    -moz-transform: translateX(100%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateX(-100%);
  }
  to {
    -webkit-transform: translateX(100%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  to {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

/* second */
#scroll-contain {
  border-radius: 5px;
  overflow: hidden;
}

#scroll-tex {
  text-align: right;

  /* animation properties */
  -moz-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);

  -moz-animation: my-animation 5s linear infinite;
  -webkit-animation: my-animation 5s linear infinite;
  animation: my-animation 5s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateX(-100%);
  }
  to {
    -moz-transform: translateX(100%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateX(-100%);
  }
  to {
    -webkit-transform: translateX(100%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  to {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.hero .content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.hero .col-1 {
  padding: 1rem 1rem 1rem 0;
  height: 70vh;
  display: flex;
  justify-content: center;
}
.hero .col-1 h1 {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
}
.hero .col-1 p {
  font-size: 1rem;
  color: var(--grey);
  line-height: 1.4;
  margin-top: 1.2rem;
}
.hero .used-by p {
  color: #000;
  font-size: 12px;
  margin: 1rem 0 1rem 0;
}
.hero .col-1 .icons i {
  color: snow;
  padding-right: 1rem;
}
.hero .col-1 {
  padding: 1rem;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.8rem;
}
.hero .col-1 p {
  color: #fff;
}
.hero .form-layout {
  width: 400px;
  margin: auto;
  margin-top: 3rem;
  background-color: #fff;
  border-radius: 6px;
}
.hero .form-container {
  padding: 1.5rem 2rem;
}
.hero .sign-in-txt {
  margin-bottom: 10px;
  text-align: center;
  padding-bottom: 1rem;
}
.social-login i {
  background-color: #fff;
  padding: 16px 45px 6px 45px;
  border: 1px solid var(--lightgrey);
  border-radius: 4px;
}

.col-2 .divider p span {
  background-color: #fff;
  padding: 0 10px;
}

.col-2 .divider p {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid var(--lightgrey);
  line-height: 0.1rem;
  margin: 2rem 0 2rem;
}

form input {
  display: block;
  width: 100%;
  margin: 1.2rem 0;
  padding: 8px;
  border: 1px solid var(--lightgrey);
  border-radius: 4px;
}
.hero .form-footer {
  border-top: 1px solid var(--lightgrey);
  background-color: #f8f8f8;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.form-footer p {
  padding: 1.5rem 2rem;
}
.form-footer span {
  font-weight: 500;
}

@media screen and (max-width: 960px) {
  .hero {
    height: auto;
    width: 100%;
  }
  .hero .container {
    padding: 0 1rem;
  }
  .hero .content {
    grid-template-columns: 1fr;
  }
  .hero .col-1 {
    height: 90vh;
    padding: 0.2rem;
    max-width: 100%;
  }
  .hero h1 {
    font-size: 32px;
  }
  .hero .col-2 {
    display: block;
  }
  #scroll-tex {
    font-size: 1px;
    width: 20rem;
  }
  #scroll-text {
    font-size: 2px;
    width: '100%';
  }
}
