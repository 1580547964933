.testimonial {
  width: 100%;
  color: #fff;
  margin: 3rem 0;
  background-color: #fff;
}
.testimonial .container {
  max-width: 1240px;
  padding: 1rem;
  margin: auto;
  overflow: hidden;
}
.testimonial .outline {
  background-image: linear-gradient(
      to left,
      rgba(73, 50, 173, 0.5),
      rgba(73, 50, 173, 0.9)
    ),
    url('https://images.pexels.com/photos/7680637/pexels-photo-7680637.jpeg?auto=compress&cs=tinysrgb&w=1250');
  width: 100%;
  height: 450px;
  background-position: left;
  border-radius: 10px;
  background-repeat: no-repeat;
}
.testimonial .content {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* max-width: 100%; */
  max-width: 50%;
  justify-content: center;
  padding: 3rem;
}

.testimonial .body {
  padding: 1.2rem 0;
}
.testimonial .name p {
  font-size: 14px;
  color: #fff;
}
/* .testimonial .content:hover {
  background-color: red;
  max-width: 100%;
} */
.testimonial p {
  color: #fff;
}

.scroll_container {
  height: 100px;
  overflow: hidden;
}

.scroll_text {
  height: 100%;
  text-align: center;

  /* animation properties */
  -moz-transform: translateY(100%);
  -webkit-transform: translateY(100%);
  transform: translateY(100%);

  -moz-animation: swipe 8s linear infinite;
  -webkit-animation: swipe 8s linear infinite;
  animation: swipe 8s linear infinite;
}

/* for Firefox */
@-moz-keyframes swipe {
  from {
    -moz-transform: translateY(100%);
  }
  to {
    -moz-transform: translateY(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes swipe {
  from {
    -webkit-transform: translateY(100%);
  }
  to {
    -webkit-transform: translateY(-100%);
  }
}

@keyframes swipe {
  from {
    -moz-transform: translateY(100%);
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  to {
    -moz-transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@media screen and (max-width: 960px) {
  .testimonial {
    margin: 1rem 0;
  }
  .testimonial .content {
    max-width: 100%;
  }
  .testimonial .outline {
    background-image: linear-gradient(
        to left,
        rgba(73, 50, 173, 0.5),
        rgba(73, 50, 173, 0.9)
      ),
      url('https://images.pexels.com/photos/7680687/pexels-photo-7680687.jpeg?auto=compress&cs=tinysrgb&w=400');
    width: 100%;
    height: 450px;
    background-position: right;
    border-radius: 10px;
    background-repeat: no-repeat;
  }
}
