.all .tab {
  font-size: 20px;
  color: blue;
  font-weight: bold;
}

@media screen and (max-width: 960px) {
  .all .tab {
    font-size: 11px;
  }
}
