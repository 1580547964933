.pricing {
  width: 100%;
  height: 100%;
  margin-top: 5rem;
  background-color: #fff;
}
.pricing .container {
  max-width: 1240px;
  padding: 1rem;
  margin: auto;
  overflow: hidden;
}
.pricing .top-content {
  display: grid;
  grid-template-columns: 3fr 1fr;
  border-bottom: 1px solid var(--lightgrey);
  padding-bottom: 3rem;
}
.pricing .top-content h2 {
  font-size: 38px;
  padding-bottom: 0.5rem;
}
.pricing .top-content span {
  color: var(--primary);
}
.pricing .top-content p {
  color: var(--grey);
}
.pricing .bottom-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  margin: 3rem auto;
}
.pricing .card {
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 1rem 0;
}

.pricing .card div {
  display: flex;
  border-bottom: 1px solid var(--lightgrey);
  padding: 1rem 0;
}
.pricing .card .no-border {
  border: none;
}
.pricing .card span {
  color: var(--primary);
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
}
.pricing .card h3 {
  font-size: 28px;
  margin: 0.7rem;
}

@media screen and (max-width: 960px) {
  .pricing .top-content {
    grid-template-columns: 1fr;
  }
  .pricing .container .bottom-content {
    /* display: none; */
    grid-template-columns: 1fr;
  }
  .pricing .top-content h2 {
    font-size: 20px;
  }
  /* .img {
    width: 10px;
    height: '15rem';
  } */
}
